@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700;900&display=swap');
html.lenis{height:auto}.lenis.lenis-smooth{scroll-behavior:auto}.lenis.lenis-smooth [data-lenis-prevent]{overscroll-behavior:contain}.lenis.lenis-stopped{overflow:hidden}.lenis.lenis-scrolling iframe{pointer-events:none}


$body-color:rgb(169, 167, 167);

// *,html{
//     scroll-behavior: smooth;
// }

body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body{
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    background-color: rgb(36, 36, 36);
    line-height: 1.5;
    box-sizing: border-box;

    .whatsapp{
        width: 99.7%;
        position: fixed;
        //right: 5%;
        bottom: 5%;
        transform: translate(-5%,-5%);
        z-index: 999;
        transition: .3s;
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: .8rem;

        p{
            color: white;
            margin-right: 15px;
        }
        
        .img{
            width: 2.5%;
            img{
                width: 100%;
            }
        }
        

        
    }

    nav{
        //background-color: black;
        padding-top: 50px;
        position: absolute;
        top: 0;
        z-index: 10;
        .navigation{
            display: flex;
            justify-content: space-between;
            width: 90%;
             margin: auto;
            .flex1{
                width: 50%;
               
                img{
                    width: 20%;
                    cursor: pointer;
                    //opacity: 0;

                    
                }
            }

            .flex2{
                display: flex;
                width: 50%;
                align-items: center;
                justify-content: flex-end;
                margin-right: 10px;

                .flex{
                    width: 16%;
                    transition: .3s;

                    img{
                        width: 100%;
                        cursor: pointer;
                        //opacity: 0;
                        //transform: translateY(40px);

                       
                        
                    }

                    &:hover{
                        transform: scale(1.2);
                    }

                    
                }

                .menu{
                    display: none;
                }
                
            }
        }

    }

    .mobile_nav{
        position: absolute;
        top: 0;
       
        display: none;
      
    }

    .threeD_viewport{
        //width: 100%;
        overflow: hidden;
        cursor:grab;

        .banner_caption{

            width: min(480px,30%);
            position: absolute;
            top:92%;
            left: 8%;
            color: white;
            transform: translate(-8%,-92%);
            z-index: 999;

            h1{
                //text-transform: uppercase; 
                font-weight: 500;
                line-height: 1.5; 
                font-size: clamp(1.4rem,2.2vw,1.7rem);
                line-height: 1.5;
                
            }

            p{
                line-height: 2.5;
                font-size: .85rem;
            }

            button{
                border: solid white 1px;
                background-color: black;
                color:white;
                padding: 13px 45px;
                font-size: 1.2rem;
                margin-top: 15px;
                cursor: pointer;
            }
        }

        .banner_content{
            padding-bottom: 40px;
            position: absolute;
            text-align: center;
            left: 50%;
            bottom: 0%;
            transform: translate(-50%);
            color: white;
            z-index: 9999;
             .banner_content_flexbox1{
                 display: flex;
                 justify-content: center;
                 //padding: 20px 0px;
 
                 .flexbox{
                     
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     .flex{
                         width: 85%;
                        img{
                         width: 100%;
                        }
         
                     }
                 } 
     
                 
             }
 
             .banner_content_flexbox2{
                 display: flex;
                 text-align: left;
                 align-items: center;
                 justify-content: center;
                 padding: 10px 0px;
                 
                 .flex:nth-child(1){
                     width: 10%;
                     img{
                       width: 80%;
                     }
                 }
 
                 .flex:nth-child(2){
                     padding: 20px 10px;
                     font-size: 1.7rem;
                 }
                 
 
              
 
             }
 
             .instructions{
                 p{
                     color: gray;
                     font-weight: 200;
                 }
             }
 
             button{
                 padding: 15px 70px;
                 font-size: 1.3rem;
                 cursor: pointer;
             }

             .down_arrow{
                cursor: pointer;
                width: 20%;
                margin: auto;

                img{
                    width: 100%;
                }
             }
 
             
         }
    }


    .radial_gradient{
        width: 100%;
        height: 100vh;
        background-image: radial-gradient(rgba(59, 59, 59, 0.911),rgba(39, 39, 39, 0.46),rgba(0, 0, 0, 0.304),rgb(32, 32, 32));
        position: absolute;
        top: 0;
        z-index: -1;
        background-position-y: -500px;
        

       

        .instructions{
            color: gray;
            position: absolute;
            bottom: 10%;
            left: 55%;
            transform: translate(-55%,-10%);
            font-size: .6rem;
        }

        .material-symbols-outlined {
            color: rgb(169, 169, 169);
            font-size: clamp(1.5rem,3vw,2.5rem);
            font-variation-settings:
            'FILL' 0,
            'wght' 400,
            'GRAD' 0,
            'opsz' 48
        }

        .down_arrow{
            position:absolute;
            left: 55%;
            bottom: 5%;
            transform: translate(-55%,-5%);
            cursor: pointer;
        }

        .social_section{
            position: absolute;
            top: 60%;
            right: 2%;
            transform: translate(-2%,-60%);
            height: fit-content;
            width: 5.5%;
            //opacity: 0;
            
           .line{
            min-height: 30vh;
            border-left: 1px solid rgb(97, 97, 97);
            margin-left: 16px;

            
           }

           .connect{
            color: white;
            min-height:15vh ;
            position: relative;
           
                p{
                    width: 300px;
                    height: fit-content;
                    font-size: clamp(.5rem,1vw,.8rem);
                    transform-origin: top left;
                    position: absolute;
                    left: 0;
                    top: 90%;
                    transform: rotate(-90deg) translate(-5%,35%) ;
                    margin: 0;
                }
            
            }

            .socials{
                display: flex;
                flex-direction: column;
                min-height: 18vh;
                justify-content: space-around;
                margin-top: 20px;
                
                img{
                    width: 40%;
                }
            }
        }

        
    }

    

    .banner_content_holder{
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        color: white;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-align: center;
        pointer-events: none;
        z-index: 999;
        cursor: pointer;

       

       

    }

    section{

       
        .rolls_sizes{
            display: flex;
            justify-content: center;
            width: 100%;
            height:120vh;
            position: relative;
            top: 0;
            background-color: black;
            //overflow: hidden;

            .container{
                width: 65%;
                //min-height: 40vh;
                margin: 0 auto;
                position: absolute;
                color: white;
                z-index: 2;
                top: 0%;
                transform: translateY(-0%); 


                .rolls_sizes_content{
                    margin-top: min(50px,4vh);
                    .rolls{
                        font-size: clamp(1.3rem,3vw,2.5rem);
                        opacity: 0;
                        padding-top: 100px;
                        
                    }

                    p{
                        line-height: 2;
                        opacity: 0;
                    }

                    button{
                        padding: 15px 35px;
                        border: white solid 1px;
                        background-color: black;
                        color: white;
                        font-size: clamp(1rem,1.5vw,1.2rem);
                        opacity: 0;
                    }

                   
                }

               
            }

            .application_content{
                color: white;
                width: calc(30% + 50px);
                position: fixed;
                top: 15%;
                right:10%;
                z-index: 10;
                opacity: 0;
                pointer-events: none;
                
                //transition: opacity .5s;

                h1{
                    font-size: clamp(1rem,2vw,2.5rem);
                    font-weight: 500;
                    margin: 0;
                }

                p{
                    line-height: 2;
                    font-size: clamp(1rem,2vw,1rem);
                    color: $body-color
                }
                ul{
                    li{
                        line-height: 3;
                        font-size: .9rem;
                    }
                }
            }

            .vid_container{
                width: 85%;
                height: 80vh;
                transform: translate3d(0,0,0);
                position: sticky;
                top: 10%;
                margin-top: 100px;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: hidden;
                //border: 1px red solid;

                video{
                    width: 100%;
                    height: 100vh;
                    margin-top: 100px;
                    //mix-blend-mode: screen;
                   
                }

              
                .gradient_background{
                    width: 100%;
                    height: 100vh;
                    background-image: radial-gradient(circle,rgba(0, 0, 0, 0.282),rgba(0, 0, 0, 0.655),rgba(0, 0, 0, 0.655),rgba(0, 0, 0, 0.953),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0));
                    position: absolute;
                    top: 0;
                    z-index: 1;
                    color: white;
                    mix-blend-mode: multiply;
                    //opacity: 0;
                   
                }

               .rolls_range_mobile{
                display: none;
               }

               .range_container{
                display: none;
               }

                // .gradient_background2{
                //     width: 100%;
                //     height: 100vh;
                //     background-image: radial-gradient(circle,rgba(0, 0, 0, 0.282),rgba(0, 0, 0, 0.655),rgba(0, 0, 0, 0.655),rgba(0, 0, 0, 0.953),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0));
                //     position: absolute;
                //     top: 0;
                //     z-index: 1;
                //     color: white;
                   
                //     //opacity: 0;
                   
                // }

            }
            
         
        }

        .rolls_sizes_2{
            height: 180vh;
            background-color: rgb(19, 19, 19);
            position: relative;
            padding-top: 100px;

            .arrow_left{
                position: absolute;
                top: 38%;
                left: 42%;
                transform: translate(-42%,-38%) ;
                cursor: pointer;
                width: 4.5%;
                z-index: 9999;

                img{
                    width: 100%;
                }
            }

            .arrow_right{
                position: absolute;
                top: 38%;
                left: 49%;
                transform: translate(-49%,-38%);
                cursor: pointer;
                width: 5%;
                z-index: 9999;

                img{
                    width: 100%;
                }
            }

            .container{
                width: 100%;
                margin: auto;
                color: white;
                position: absolute;
                overflow: hidden;
                opacity: 0;
                transform: translateY(30%);
                transition: 1s;


                .cash_register_atm{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    min-height: 60vh;

                    .flex{
                        width: 40%;
                        min-height: 60vh;
                        position: relative;

                        .content{
                            width: calc(50% + 100px);
                            line-height: 2;

                            .mobile_content{
                                display: none;
                            }

                            h1{
                                font-weight: 500;
                            }

                            p{
                                color:$body-color;
                                font-size: 1.1rem;
                            }

                            h2{
                                font-size: clamp(1rem,2vw,2.5rem);
                                font-weight: 500;
                            }
                        }

                        
                    }

                    .flex:nth-child(1){
                        display: flex;
                        align-items: center;
                        //justify-content: center;
                        margin-left: 80px;
                    }

                    .flex:nth-child(2){
                        width: 60%;
                     
                        //background-color: red;
                        overflow: hidden;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .carousel{
                            width: 240%;
                            height: 100%;
                            display: flex;
                            justify-content: space-between;
                            transition: .5s;
                            .atm{
                                width: 33%;
                                height: 100%;
                                //background: url(../images/cash_register_img.jpeg);
                                display: flex;
                                align-items: center;
                                overflow: hidden;
                                position: relative;
                                background-size: cover;
                                transition: .5s;

                                video{
                                   position: absolute;
                                   z-index: -1;
                                    width: 100%;
                                    height: auto;

                                }
                            }

                            .printer{
                                width: 33%;
                                height: 100%;
                                //background: url(https://as1.ftcdn.net/v2/jpg/03/92/64/58/1000_F_392645825_vxz2YOeQU7HcaC3CmB8hJP0LtJxPN1O8.jpg);
                                opacity: 0.3;
                                transition: .5s;
                                background-size: cover;
                                overflow: hidden;
                                position: relative;
                                display: flex;
                                align-items: center;

                                video{
                                    position: absolute;
                                    z-index: -1;
                                     width: 100%;
                                     height: auto;
 
                                 }
                            }

                            .cash_register{
                                width: 33%;
                                height: 100%;
                                //background: url(https://as1.ftcdn.net/v2/jpg/01/74/47/54/1000_F_174475465_SCpTfr42tfxHeIQmTnUEwPJed3CgW11o.jpg);
                                opacity: 0.3;
                                transition: .5s;
                                background-size: cover;
                                overflow: hidden;
                                position: relative;
                                display: flex;
                                align-items: center;

                                video{
                                    position: absolute;
                                    z-index: -1;
                                     width: 100%;
                                     height: auto;
 
                                 }
                            }


                           
                        }
                    }
                   
                }

              
                
                   
            }

            .sustainability{
                width: 100%; 
                min-height: 60vh;
                justify-content: center;
                padding-top: 0px;
                position: absolute;
                top: 80%;
                transform: translateY(-55%);
                mix-blend-mode: screen;
                color: white;
                opacity: 0;
                
               

                // .gradient_overlay{
                //     position: absolute;
                //     width: 100%;
                //     height: 200px;
                //     background: linear-gradient(to top,black, rgba(0, 0, 0, 0.69),rgba(0, 0, 0, 0));
                //     //background-color: red;
                //     bottom: 0;
                //     z-index: 10;
                //     mix-blend-mode: multiply;
                // }

                .sustainability_video{
                    position: absolute;
                    left: 45%;
                    top: 45%;
                    transform: translate(-45%,-45%);
                    video{
                        width: 65%;
                        mix-blend-mode: screen;
                        
                    }
                }

                .content{
                    position: absolute;
                    width: calc(30% + 50px);
                    left: 75%;
                    top: 50%;
                    transform: translate(-75%,-50%);

                    h5{
                        font-size: clamp(1rem,1.5vw,1.2rem);
                        font-weight: 500;
                    }

                    h1{
                        font-size: clamp(2.3rem,2vw,2.3rem);
                        font-weight: 500;
                        margin-bottom: 30px;
                    }

                    p{
                        line-height: 2.5;
                        color: $body-color;
                        font-size: .8rem;
                    }
                }
            }


            footer{
                display: flex;
                justify-content: center;
                width: 100%;
                position: absolute;
                bottom: 0;
                color: white;
                margin: 100px 0;
                

                .main_content{
                    width:85%;
                    padding-top: 100px;
                    display: flex;
                    justify-content: space-between;
                    //border-top: rgb(87, 87, 87) 1px solid;

                    .flex:nth-child(1){
                        display: flex;
                        align-items: center;
                        width: 45%;
                        .logo{
                            width: 40%;
                            
                            border-radius: 50%;
                            clip-path: circle(43%);
                            img{
                                width: 100%;
                            }
                        }

                        .content{
                            width: 50%;
                            margin-left: 50px;

                            h2{
                                font-size: clamp(1rem,2vw,1.2rem);
                                font-weight: 500;
                            }

                            p{
                                font-size: .7rem;
                                line-height: 2;
                            }
                        }
                    }

                    .flex:nth-child(2){
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 15%;
                      
                        .socials{
                            display: flex;
                            justify-content: space-between;
                            

                            .instagram,.facebook,.mail{
                                width: 18%;

                                img{
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .flex:nth-child(3){
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20%;
                    }

                }

               
            }

            
        }
    }

    .arrow_upwards{ 
        padding: 10px 15px;                   
        background-color: white;
        position: absolute;
        right: 5%;
        top: 85%;
        text-align: center;
        z-index: 100;
        
        span{
            line-height: 1.5;
            cursor: pointer;
        }
    }

    
}

