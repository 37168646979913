@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700;900&display=swap";
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-sizing: border-box;
  background-color: #242424;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  line-height: 1.5;
}

body .whatsapp {
  z-index: 999;
  cursor: pointer;
  justify-content: flex-end;
  align-items: center;
  width: 99.7%;
  font-size: .8rem;
  transition: all .3s;
  display: flex;
  position: fixed;
  bottom: 5%;
  transform: translate(-5%, -5%);
}

body .whatsapp p {
  color: #fff;
  margin-right: 15px;
}

body .whatsapp .img {
  width: 2.5%;
}

body .whatsapp .img img {
  width: 100%;
}

body nav {
  z-index: 10;
  padding-top: 50px;
  position: absolute;
  top: 0;
}

body nav .navigation {
  justify-content: space-between;
  width: 90%;
  margin: auto;
  display: flex;
}

body nav .navigation .flex1 {
  width: 50%;
}

body nav .navigation .flex1 img {
  cursor: pointer;
  width: 20%;
}

body nav .navigation .flex2 {
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  margin-right: 10px;
  display: flex;
}

body nav .navigation .flex2 .flex {
  width: 16%;
  transition: all .3s;
}

body nav .navigation .flex2 .flex img {
  cursor: pointer;
  width: 100%;
}

body nav .navigation .flex2 .flex:hover {
  transform: scale(1.2);
}

body nav .navigation .flex2 .menu {
  display: none;
}

body .mobile_nav {
  display: none;
  position: absolute;
  top: 0;
}

body .threeD_viewport {
  cursor: grab;
  overflow: hidden;
}

body .threeD_viewport .banner_caption {
  color: #fff;
  z-index: 999;
  width: min(480px, 30%);
  position: absolute;
  top: 92%;
  left: 8%;
  transform: translate(-8%, -92%);
}

body .threeD_viewport .banner_caption h1 {
  font-size: clamp(1.4rem, 2.2vw, 1.7rem);
  font-weight: 500;
  line-height: 1.5;
}

body .threeD_viewport .banner_caption p {
  font-size: .85rem;
  line-height: 2.5;
}

body .threeD_viewport .banner_caption button {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  border: 1px solid #fff;
  margin-top: 15px;
  padding: 13px 45px;
  font-size: 1.2rem;
}

body .threeD_viewport .banner_content {
  text-align: center;
  color: #fff;
  z-index: 9999;
  padding-bottom: 40px;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%);
}

body .threeD_viewport .banner_content .banner_content_flexbox1 {
  justify-content: center;
  display: flex;
}

body .threeD_viewport .banner_content .banner_content_flexbox1 .flexbox {
  justify-content: center;
  align-items: center;
  display: flex;
}

body .threeD_viewport .banner_content .banner_content_flexbox1 .flexbox .flex {
  width: 85%;
}

body .threeD_viewport .banner_content .banner_content_flexbox1 .flexbox .flex img {
  width: 100%;
}

body .threeD_viewport .banner_content .banner_content_flexbox2 {
  text-align: left;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  display: flex;
}

body .threeD_viewport .banner_content .banner_content_flexbox2 .flex:first-child {
  width: 10%;
}

body .threeD_viewport .banner_content .banner_content_flexbox2 .flex:first-child img {
  width: 80%;
}

body .threeD_viewport .banner_content .banner_content_flexbox2 .flex:nth-child(2) {
  padding: 20px 10px;
  font-size: 1.7rem;
}

body .threeD_viewport .banner_content .instructions p {
  color: gray;
  font-weight: 200;
}

body .threeD_viewport .banner_content button {
  cursor: pointer;
  padding: 15px 70px;
  font-size: 1.3rem;
}

body .threeD_viewport .banner_content .down_arrow {
  cursor: pointer;
  width: 20%;
  margin: auto;
}

body .threeD_viewport .banner_content .down_arrow img {
  width: 100%;
}

body .radial_gradient {
  z-index: -1;
  background-image: radial-gradient(#3b3b3be8, #27272775, #0000004e, #202020);
  background-position-y: -500px;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
}

body .radial_gradient .instructions {
  color: gray;
  font-size: .6rem;
  position: absolute;
  bottom: 10%;
  left: 55%;
  transform: translate(-55%, -10%);
}

body .radial_gradient .material-symbols-outlined {
  color: #a9a9a9;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  font-size: clamp(1.5rem, 3vw, 2.5rem);
}

body .radial_gradient .down_arrow {
  cursor: pointer;
  position: absolute;
  bottom: 5%;
  left: 55%;
  transform: translate(-55%, -5%);
}

body .radial_gradient .social_section {
  width: 5.5%;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 60%;
  right: 2%;
  transform: translate(-2%, -60%);
}

body .radial_gradient .social_section .line {
  border-left: 1px solid #616161;
  min-height: 30vh;
  margin-left: 16px;
}

body .radial_gradient .social_section .connect {
  color: #fff;
  min-height: 15vh;
  position: relative;
}

body .radial_gradient .social_section .connect p {
  transform-origin: 0 0;
  width: 300px;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  font-size: clamp(.5rem, 1vw, .8rem);
  position: absolute;
  top: 90%;
  left: 0;
  transform: rotate(-90deg)translate(-5%, 35%);
}

body .radial_gradient .social_section .socials {
  flex-direction: column;
  justify-content: space-around;
  min-height: 18vh;
  margin-top: 20px;
  display: flex;
}

body .radial_gradient .social_section .socials img {
  width: 40%;
}

body .banner_content_holder {
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 999;
  cursor: pointer;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
}

body section .rolls_sizes {
  background-color: #000;
  justify-content: center;
  width: 100%;
  height: 120vh;
  display: flex;
  position: relative;
  top: 0;
}

body section .rolls_sizes .container {
  color: #fff;
  z-index: 2;
  width: 65%;
  margin: 0 auto;
  position: absolute;
  top: 0%;
  transform: translateY(0%);
}

body section .rolls_sizes .container .rolls_sizes_content {
  margin-top: min(50px, 4vh);
}

body section .rolls_sizes .container .rolls_sizes_content .rolls {
  opacity: 0;
  padding-top: 100px;
  font-size: clamp(1.3rem, 3vw, 2.5rem);
}

body section .rolls_sizes .container .rolls_sizes_content p {
  opacity: 0;
  line-height: 2;
}

body section .rolls_sizes .container .rolls_sizes_content button {
  color: #fff;
  opacity: 0;
  background-color: #000;
  border: 1px solid #fff;
  padding: 15px 35px;
  font-size: clamp(1rem, 1.5vw, 1.2rem);
}

body section .rolls_sizes .application_content {
  color: #fff;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  width: calc(30% + 50px);
  position: fixed;
  top: 15%;
  right: 10%;
}

body section .rolls_sizes .application_content h1 {
  margin: 0;
  font-size: clamp(1rem, 2vw, 2.5rem);
  font-weight: 500;
}

body section .rolls_sizes .application_content p {
  color: #a9a7a7;
  font-size: clamp(1rem, 2vw, 1rem);
  line-height: 2;
}

body section .rolls_sizes .application_content ul li {
  font-size: .9rem;
  line-height: 3;
}

body section .rolls_sizes .vid_container {
  flex-direction: column;
  align-items: center;
  width: 85%;
  height: 80vh;
  margin-top: 100px;
  display: flex;
  position: sticky;
  top: 10%;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

body section .rolls_sizes .vid_container video {
  width: 100%;
  height: 100vh;
  margin-top: 100px;
}

body section .rolls_sizes .vid_container .gradient_background {
  z-index: 1;
  color: #fff;
  mix-blend-mode: multiply;
  background-image: radial-gradient(circle, #00000048, #000000a7, #000000a7, #000000f3, #0000, #0000, #0000, #0000);
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
}

body section .rolls_sizes .vid_container .rolls_range_mobile, body section .rolls_sizes .vid_container .range_container {
  display: none;
}

body section .rolls_sizes_2 {
  background-color: #131313;
  height: 180vh;
  padding-top: 100px;
  position: relative;
}

body section .rolls_sizes_2 .arrow_left {
  cursor: pointer;
  z-index: 9999;
  width: 4.5%;
  position: absolute;
  top: 38%;
  left: 42%;
  transform: translate(-42%, -38%);
}

body section .rolls_sizes_2 .arrow_left img {
  width: 100%;
}

body section .rolls_sizes_2 .arrow_right {
  cursor: pointer;
  z-index: 9999;
  width: 5%;
  position: absolute;
  top: 38%;
  left: 49%;
  transform: translate(-49%, -38%);
}

body section .rolls_sizes_2 .arrow_right img {
  width: 100%;
}

body section .rolls_sizes_2 .container {
  color: #fff;
  opacity: 0;
  width: 100%;
  margin: auto;
  transition: all 1s;
  position: absolute;
  overflow: hidden;
  transform: translateY(30%);
}

body section .rolls_sizes_2 .container .cash_register_atm {
  justify-content: space-between;
  width: 100%;
  min-height: 60vh;
  display: flex;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex {
  width: 40%;
  min-height: 60vh;
  position: relative;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex .content {
  width: calc(50% + 100px);
  line-height: 2;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex .content .mobile_content {
  display: none;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex .content h1 {
  font-weight: 500;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex .content p {
  color: #a9a7a7;
  font-size: 1.1rem;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex .content h2 {
  font-size: clamp(1rem, 2vw, 2.5rem);
  font-weight: 500;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex:first-child {
  align-items: center;
  margin-left: 80px;
  display: flex;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) {
  width: 60%;
  overflow: hidden;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2)::-webkit-scrollbar {
  display: none;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel {
  justify-content: space-between;
  width: 240%;
  height: 100%;
  transition: all .5s;
  display: flex;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel .atm {
  background-size: cover;
  align-items: center;
  width: 33%;
  height: 100%;
  transition: all .5s;
  display: flex;
  position: relative;
  overflow: hidden;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel .atm video {
  z-index: -1;
  width: 100%;
  height: auto;
  position: absolute;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel .printer {
  opacity: .3;
  background-size: cover;
  align-items: center;
  width: 33%;
  height: 100%;
  transition: all .5s;
  display: flex;
  position: relative;
  overflow: hidden;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel .printer video {
  z-index: -1;
  width: 100%;
  height: auto;
  position: absolute;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel .cash_register {
  opacity: .3;
  background-size: cover;
  align-items: center;
  width: 33%;
  height: 100%;
  transition: all .5s;
  display: flex;
  position: relative;
  overflow: hidden;
}

body section .rolls_sizes_2 .container .cash_register_atm .flex:nth-child(2) .carousel .cash_register video {
  z-index: -1;
  width: 100%;
  height: auto;
  position: absolute;
}

body section .rolls_sizes_2 .sustainability {
  mix-blend-mode: screen;
  color: #fff;
  opacity: 0;
  justify-content: center;
  width: 100%;
  min-height: 60vh;
  padding-top: 0;
  position: absolute;
  top: 80%;
  transform: translateY(-55%);
}

body section .rolls_sizes_2 .sustainability .sustainability_video {
  position: absolute;
  top: 45%;
  left: 45%;
  transform: translate(-45%, -45%);
}

body section .rolls_sizes_2 .sustainability .sustainability_video video {
  mix-blend-mode: screen;
  width: 65%;
}

body section .rolls_sizes_2 .sustainability .content {
  width: calc(30% + 50px);
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-75%, -50%);
}

body section .rolls_sizes_2 .sustainability .content h5 {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  font-weight: 500;
}

body section .rolls_sizes_2 .sustainability .content h1 {
  margin-bottom: 30px;
  font-size: clamp(2.3rem, 2vw, 2.3rem);
  font-weight: 500;
}

body section .rolls_sizes_2 .sustainability .content p {
  color: #a9a7a7;
  font-size: .8rem;
  line-height: 2.5;
}

body section .rolls_sizes_2 footer {
  color: #fff;
  justify-content: center;
  width: 100%;
  margin: 100px 0;
  display: flex;
  position: absolute;
  bottom: 0;
}

body section .rolls_sizes_2 footer .main_content {
  justify-content: space-between;
  width: 85%;
  padding-top: 100px;
  display: flex;
}

body section .rolls_sizes_2 footer .main_content .flex:first-child {
  align-items: center;
  width: 45%;
  display: flex;
}

body section .rolls_sizes_2 footer .main_content .flex:first-child .logo {
  clip-path: circle(43%);
  border-radius: 50%;
  width: 40%;
}

body section .rolls_sizes_2 footer .main_content .flex:first-child .logo img {
  width: 100%;
}

body section .rolls_sizes_2 footer .main_content .flex:first-child .content {
  width: 50%;
  margin-left: 50px;
}

body section .rolls_sizes_2 footer .main_content .flex:first-child .content h2 {
  font-size: clamp(1rem, 2vw, 1.2rem);
  font-weight: 500;
}

body section .rolls_sizes_2 footer .main_content .flex:first-child .content p {
  font-size: .7rem;
  line-height: 2;
}

body section .rolls_sizes_2 footer .main_content .flex:nth-child(2) {
  justify-content: center;
  align-items: center;
  width: 15%;
  display: flex;
}

body section .rolls_sizes_2 footer .main_content .flex:nth-child(2) .socials {
  justify-content: space-between;
  display: flex;
}

body section .rolls_sizes_2 footer .main_content .flex:nth-child(2) .socials .instagram, body section .rolls_sizes_2 footer .main_content .flex:nth-child(2) .socials .facebook, body section .rolls_sizes_2 footer .main_content .flex:nth-child(2) .socials .mail {
  width: 18%;
}

body section .rolls_sizes_2 footer .main_content .flex:nth-child(2) .socials .instagram img, body section .rolls_sizes_2 footer .main_content .flex:nth-child(2) .socials .facebook img, body section .rolls_sizes_2 footer .main_content .flex:nth-child(2) .socials .mail img {
  width: 100%;
}

body section .rolls_sizes_2 footer .main_content .flex:nth-child(3) {
  justify-content: center;
  align-items: center;
  width: 20%;
  display: flex;
}

body .arrow_upwards {
  text-align: center;
  z-index: 100;
  background-color: #fff;
  padding: 10px 15px;
  position: absolute;
  top: 85%;
  right: 5%;
}

body .arrow_upwards span {
  cursor: pointer;
  line-height: 1.5;
}

/*# sourceMappingURL=index.50f107bc.css.map */
